import React from 'react';
import { keyframes } from '@stitches/react';
import * as DialogPrimitive from '@radix-ui/react-dialog';
import { motion } from 'framer-motion';
import { styled } from '../../../theme.stitches';

const overlayShow = keyframes({
  '0%': { opacity: 0 },
  '100%': { opacity: 1 },
});

const contentShow = keyframes({
  '0%': { opacity: 0, transform: 'translate(-50%, 0%) scale(.96)' },
  '100%': { opacity: 1, transform: 'translate(-50%, -50%) scale(1)' },
});

const contentShowMobile = keyframes({
  '0%': { opacity: 0, transform: 'translate(-50%, 0%)' },
  '100%': { opacity: 1, transform: 'translate(-50%, -50%)' },
});

const StyledOverlay = styled(DialogPrimitive.Overlay, {
  backgroundColor: 'rgba(0, 0, 0, 0.5)',
  position: 'fixed',
  zIndex: '9991',
  inset: 0,
  '@media (prefers-reduced-motion: no-preference)': {
    animation: `${overlayShow} 400ms cubic-bezier(0.16, 1, 0.3, 1) forwards`,
  },
});

const StyledContent = styled(DialogPrimitive.Content, {
  backgroundColor: 'white',
  zIndex: '9992',

  borderRadius: '1rem',
  boxShadow: 'hsl(206 22% 7% / 35%) 0px 10px 38px -10px, hsl(206 22% 7% / 20%) 0px 10px 20px -15px',
  position: 'fixed',
  top: '80%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '90vw',
  maxWidth: '40rem',
  maxHeight: '85vh',
  padding: '4rem',
  '@media (prefers-reduced-motion: no-preference)': {
    animation: `${contentShow} 1s cubic-bezier(0.16, 1, 0.3, 1) forwards`,
  },
  '&:focus': { outline: 'none' },
  '@mobile': {
    width: '101vw',
    boxSizing: 'border-box',
    padding: '4rem 1.5rem 2rem 2rem',
    borderRadius: '2rem',
    top: '60%',
    '@media (prefers-reduced-motion: no-preference)': {
      animation: `${contentShowMobile} 1s cubic-bezier(0.16, 1, 0.3, 1) forwards`,
    },
  }
});

const Portal = motion(DialogPrimitive.Portal)
const MotionContent = motion(StyledContent);

function Content({ children, ...props }: any) {
  return (
    <Portal>
      <StyledOverlay />
      <MotionContent
        {...props}>
        {children}
      </MotionContent>
    </Portal>
  );
}

// Exports
export const Dialog = DialogPrimitive.Root;
export const DialogTrigger = DialogPrimitive.Trigger;
export const DialogContent = Content;
export const DialogClose = DialogPrimitive.Close;
