import { motion } from 'framer-motion';
import React, { useEffect, useRef } from 'react'
import generateSection5Animations from '../animation/Section5';
import Container from '../components/core/container/Container';
import ScrollMore from '../components/ScrollMore';
import useIsMobile from '../hooks/useMobile';
import { styled } from '../theme.stitches'

const SectionWrapper = styled('div', {
    minHeight: '100vh',
    height: 'auto',
    backgroundColor: '#222222',
});

const TitleContainer = styled('div', {
    marginBottom: '2rem',
    paddingTop: '5rem',

    '@smallDesktop': {
        paddingTop: '2rem',
        marginBottom: '3rem',
    },

    '@mobile': {
        paddingTop: '2rem'
    }
});

const Title = styled('h3', {
    fontFamily: '$primary',
    fontSize: '2rem',
    lineHeight: '3rem',
    fontWeight: '600',
    color: '$white',
    textAlign: 'center',

    "@mobile": {
        fontSize: '1.5rem',
        lineHeight: '2rem',

    }
});

const ChartWrapper = styled('div', {
    margin: '0 auto',
    width: '80%',
    'img': {
        marginTop: '2rem',
        width: '100%'
    },
    '@smallDesktop': {
        width: '80%',
        margin: '0 auto',
    },

    '@mobile': {
        width: 'auto',
        padding: '0 5rem',
        paddingBottom: '4rem',
        boxSizing: 'border-box',
        height: '70vh',
        overflowX: 'hidden',
        'img': {
            width: 'auto',
            height: '100%'
        }
    }
});

const SubTitle = styled('h3', {
    fontFamily: '$primary',
    fontSize: '1.4rem',
    lineHeight: '2rem',
    fontWeight: '400',
    color: '$white',
    textAlign: 'center',

    '@mobile': {
        color: 'black',
        fontSize: '1.3rem',
        lineHeight: '1.8rem'
    }
});

const SecondSectionWrapper = styled('div', {
    position: 'absolute',
    width: '100%',
    top: 0
});


const Section5 = () => {

    const isMobile = useIsMobile()
    const scrollContainer = useRef(null);

    useEffect(() => {

        generateSection5Animations(isMobile)
        console.log(isMobile);

    }, [isMobile])

    return (

        <SectionWrapper className="section-5-container">
            <TitleContainer>
                <Container>
                    <Title className="section-5--title">
                        Commonly used agroecological practices on <br /> Scottish farms
                    </Title>
                </Container>
            </TitleContainer>


            {isMobile ? (
                <>
                    <ScrollMore white />
                    <ChartWrapper ref={scrollContainer}>
                        <motion.img
                            drag={"x"}
                            dragElastic={0.05}
                            dragConstraints={{
                                left: -700,
                                right: 0
                            }}
                            dragTransition={{
                                bounceStiffness: 100,
                                bounceDamping: 100,
                                power: .2
                            }}
                            src='Section5Chart.svg'
                            alt="Section 5 Chart" />
                    </ChartWrapper>

                </>
            ) : (
                <Container>
                    <ChartWrapper className="section-5-chart-1">
                        <img src='Section5Chart.svg' alt="Section 5 Chart" />
                    </ChartWrapper>
                </Container>
            )}

            <SecondSectionWrapper>
                {!isMobile && (
                    <Container>
                        <TitleContainer>
                            <Title className="section-6--title">Positive change through agroecological practices</Title>
                            <SubTitle className="section-6--title">Did agroecological practices help improve bioversity and resilience?</SubTitle>
                        </TitleContainer>
                    </Container>
                )}


                {isMobile ? (
                    <></>
                ) : (
                    <Container>
                        <ChartWrapper className="section-6-chart">
                            <img src='Section6Chart.svg' alt="Section 6 Chart" />
                        </ChartWrapper>
                    </Container >
                )}
            </SecondSectionWrapper>
        </SectionWrapper>
    )
}

export default Section5