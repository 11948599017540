import { createStitches } from '@stitches/react';

const { styled, css, keyframes } = createStitches({
    theme: {
        colors: {

            primary: '#29A4CD',
            buttonShadow: '#29A4CD',

            grey: '#323231',
            grey1: '#E7E7E6',
            grey2: '#D0D1CF',
            grey3: '#BBBCBA',
            grey4: '#A7A8A7',
            grey5: '#949594',
            grey6: '#808180',
            grey7: '#5D5E5D',
            grey8: '#484948',
            grey9: '#323231',
            grey10: '#1A1919',

            black: '#000000', 
            white: '#FFFFFF',
        },
        fontSizes: {
            super: '8.75rem',
            h1: '6rem',
            h2: '5.25rem',
            h3: '4.5rem',
            h4: '3rem',
            h5: '2.25rem',
            h6: '1.75rem',
            subHeading: '2.5rem',
            paragraphLg: '1.25rem',
            paragraph: '1rem',
            labelLg: '1rem',
            labelSm: '.75rem',
            captionMed: '.65rem',
            captionSm: '.625rem',

            //mobile

            mobileSuper: '4.5rem',
            mobileH1: '4rem',
            mobileH2: '3rem',
            mobileH3: '2.5rem',
            mobileH4: '2.25rem',
            mobileH5: '1.5rem',
            mobileH6: '1.125rem',
            mobileSubHeading: '2rem',
            mobileParagraphLg: '1.125rem',
            mobileParagraph: '1.125 rem',
            mobileLabelLg: '1.125rem',
            mobileLabelSm: '1.125rem',
            mobileCaptionMed: '.75rem',
            mobileCaptionSm: '.75rem',
        },
        fontWeights: {
            super: '700',
            h1: '700',
            h2: '700',
            h3: '700',
            h4: '400',
            h5: '600',
            h6: '600',
            subHeading: '400',
            paragraphLg: '400',
            paragraph: '400',
            labelLg: '700',
            labelSm: '400',
            captionMed: '600',
            captionSm: '500',
        },
        lineHeights: {
            super: '10rem',
            h1: '6.5rem',
            h2: '5.75rem',
            h3: '5rem',
            h4: '3.5rem',
            h5: '2.5rem',
            h6: '2rem',
            subHeading: '3.5rem',
            paragraphLg: '1.875rem',
            paragraph: '1.9rem',
            labelLg: '1.25rem',
            labelSm: '1rem',
            captionMed: '1rem',
            captionSm: '1rem',

            //mobile
            mobileSuper: '4.5rem',
            mobileH1: '4.5rem',
            mobileH2: '3.5rem',
            mobileH3: '3rem',
            mobileH4: '2.25rem',
            mobileH5: '2rem',
            mobileH6: '1.5rem',
            mobileSubHeading: '2.5rem',
            mobileParagraphLg: '1.6875rem',
            mobileParagraph: '1.6875rem',
            mobileLabelLg: '1.6875rem',
            mobileLabelSm: '1.6875rem',
            mobileCaptionMed: '.75rem',
            mobileCaptionSm: '.75rem',
        },
        gradients: {
            salmonRed: 'linear-gradient(225deg, #B11840 0%, #ED859B 100%)',
            blueGreen: 'linear-gradient(225deg, #49BED6 0%, #5ABBA4 100%)',
            orangeYellow: 'linear-gradient(225deg, #F0AB30 0%, #EDCC00 100%)',
            tomatoPlum: 'linear-gradient(225deg, #EC643E 0%, #6A3B8A 100%)',
            yellowGreen: 'linear-gradient(225deg, #EDCC00 0%, #5ABBA4 100%)',
        },
        fonts: {
            primary: 'Museo Sans',  
            secondary: "Museo Sans', sans-serif",
        }
    },
    media: {
        mobileSmall: '(max-width: 377px)',
        mobile: '(max-width: 1020px)',
        tablet: '(min-width: 480px) and (max-width: 1020px)',
        smallDesktop: '(min-width: 1200px) and (max-width: 1590px)',
        desktop: '(min-width: 1200px)',
    },


})

export { styled, css, keyframes };