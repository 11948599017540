import { motion } from 'framer-motion';
import React, { useEffect, useRef } from 'react'
import animateTimelineSection3 from '../animation/Section3';
import Animate from '../components/Animate';
import Container from '../components/core/container/Container';
import Flex from '../components/core/flex/Flex'
import { H4 } from '../components/core/text'
import ScrollMore from '../components/ScrollMore';
import useIsMobile from '../hooks/useMobile';
import { styled } from '../theme.stitches'

const ContentContainer = styled('div', {
    padding: '3rem 0 0 0',
    zIndex: '100',
    position: 'relative',

    '@mobile': {
        padding: '3rem 0 0 0',
    },

});

const SubTitle = styled(motion.span, {
    fontSize: '2.5rem',
    lineHeight: '2.8rem',
    fontFamily: '$primary',
    color: '#8DA441',
    textAlign: 'center',
    marginBottom: '1rem',

    '@mobile': {
        color: 'black',
        fontSize: '1.7rem',
        padding: '0 .5rem',
        lineHeight: '2.5rem',
    }
})

const MainTitle = styled(motion.span, {
    fontSize: '5rem',
    lineHeight: '5.6rem',
    fontFamily: '$primary',
    fontWeight: '900',
    color: '#6C7E35',
    textAlign: 'center',
    marginBottom: '8rem',


    '@mobile': {
        color: 'black',
        fontSize: '2rem',
        padding: '0 .5rem',
        lineHeight: '2.5rem',

        marginBottom: '4.125rem'
    }
})

const SectionCard = styled(motion.div, {
    boxSizing: 'border-box',
    border: '1px solid #ECF2DB',
    boxShadow: '0px 2px 8px rgba(189, 213, 116, 0.2)',
    borderRadius: '0.5rem',
    padding: '3rem 1.5rem',
    width: '18.25rem',
    height: '22.25rem',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    margin: '0 1.5rem 1.5rem 0',
    transition: 'all 0.3s ease-in-out',
    background: 'rgba(255, 255, 255, 1)',
    backdropFilter: 'blur(20px)',

    '@smallDesktop': {
        width: '20.75rem',
        height: '20.75rem',
        padding: '2.5rem 1.5rem',
    },

    '@mobile': {
        margin: 0
    },

    '&:hover': {
        transform: 'scale(1.05)',
        boxShadow: '0px 8px 8px rgba(189, 213, 116, 0.4)',
    },
});

const SectionCardOutter = styled(motion.div, {
    width: '100vw',
    height: '25rem',
    scrollSnapAlign: "center",
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
});

const CardTitle = styled('span', {
    fontFamily: '$primary',
    fontSize: '1.5rem',
    fontWeight: '700',
    textAlign: 'center',
    color: '#A6BF49',
    textTransform: 'uppercase',
});

const CardBody = styled('p', {
    fontFamily: '$primary',
    fontSize: '1rem',
    lineHeight: '1.5rem',
    fontWeight: '400',
    textAlign: 'center',
    color: '#222222',
});

const CardsWrapper = styled(motion.div, {
    display: 'inline-flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',

    flexWrap: 'wrap',
    "@smallDesktop": {
    },

    '@mobile': {
        alignContent: 'flex-start',
        width: 5 * window.innerWidth
    }

});

const CardOutterWrapper = styled(motion.div, {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',

    '@mobile': {
        overflowX: 'scroll',
        scrollSnapType: 'x mandatory',
        scrollbarWidth: 'none',
        '-ms-overflow-style': 'none',
        '&::-webkit-scrollbar': {
            display: 'none',
        },
    }
});

const CardOutterWrapperMobile = styled(motion.div, {


    overflowX: 'scroll',
    scrollSnapType: 'x mandatory',
    scrollbarWidth: 'none',
    '-ms-overflow-style': 'none',
    '&::-webkit-scrollbar': {
        display: 'none',
    },
});

const BackgroundWrapper = styled('div', {
    position: 'absolute',
    zIndex: '1000',
    width: '100%',
    bottom: 0,
    'img': {
        width: '100%',
        position: 'absolute',
        bottom: 0
    },
    '@mobile': {
        bottom: '-1%'
    }
});

const SectionWrapper = styled('div', {
    position: 'relative',
    width: '100vw',
    minHeight: '90rem',
    height: '130vh',
    overflow: 'hidden',
    background: 'linear-gradient(180deg, rgba(60, 173, 255, 0) 0%, #3CADFF 100%)',
    '@mobile': {
        minHeight: '55rem',
    },


    '@smallDesktop': {
        minHeight: '200vh',
    },
});

const cardVariant = {
    initial: { opacity: 0 },
    animate: { opacity: 1, transition: { duration: 0.5 } },
}

const Section3 = () => {

    const scrollContainer = useRef(null);
    const isMobile = useIsMobile();

    useEffect(() => animateTimelineSection3(isMobile), []);

    return (
        <SectionWrapper className="section-3-container">
            <BackgroundWrapper>
                <motion.img src={isMobile ? "Section3GrassMobile.svg" : '/section1Grass.svg'} />
            </BackgroundWrapper>
            <ContentContainer>
                <Container>
                    <Flex direction={'column'} justifyCenter>
                        <SubTitle className="section-3--title"> Farming systems which could form part of an  </SubTitle>
                        <MainTitle className="section-3--title">AGROECOLOGICAL Approach:</MainTitle>
                    </Flex>
                </Container>

                {isMobile ? (
                    <>
                        <ScrollMore />
                        <CardOutterWrapperMobile ref={scrollContainer}>
                            <CardsWrapper
                                className="cards-wrapper-3"
                            >
                                <SectionCardOutter>
                                    <SectionCard className='section-card-3' variants={cardVariant} id="section-card1">
                                        <CardTitle>Regenerative</CardTitle>
                                        <CardBody>Uses soil conservation to enhance the environmental, social and economic aspects of food production.</CardBody>
                                    </SectionCard>
                                </SectionCardOutter>

                                <SectionCardOutter>
                                    <SectionCard className='section-card-3' variants={cardVariant} id="section-card2">
                                        <CardTitle>ORGANIC</CardTitle>
                                        <CardBody>Holistic system which enhances agro-ecosystem health and animal welfare. Uses naturally derived pesticides, and avoids artifical inputs, genetic modification and antibiotics.</CardBody>
                                    </SectionCard>
                                </SectionCardOutter>


                                <SectionCardOutter>
                                    <SectionCard className='section-card-3' variants={cardVariant} id="section-card3">
                                        <CardTitle>Pasture for Life</CardTitle>
                                        <CardBody>Promotes livestock grazing on grass and forage their entire lives.</CardBody>
                                    </SectionCard>
                                </SectionCardOutter>

                                <SectionCardOutter>
                                    <SectionCard className='section-card-3' variants={cardVariant} id="section-card4">
                                        <CardTitle>Linking
                                            environment and
                                            Farming (LEAF)</CardTitle>
                                        <CardBody>Enables sustainable farming through integrated farm management - a site-specific approach that uses the best of modern technology and traditional methods.</CardBody>
                                    </SectionCard>
                                </SectionCardOutter>


                                <SectionCardOutter>
                                    <SectionCard className='section-card-3' variants={cardVariant} id="section-card5">
                                        <CardTitle>Permaculture</CardTitle>
                                        <CardBody>Tackles how to grow food, build houses and create communities, while minimising environmental impact.</CardBody>
                                    </SectionCard>
                                </SectionCardOutter>

                            </CardsWrapper>
                        </CardOutterWrapperMobile>
                    </>
                ) : (
                    <CardsWrapper
                        className="cards-wrapper-3"
                    >
                        <SectionCard className='section-card-3' variants={cardVariant} id="section-card1">
                            <CardTitle>Regenerative</CardTitle>
                            <CardBody>Uses soil conservation to enhance the environmental, social and economic aspects of food production.</CardBody>
                        </SectionCard>

                        <SectionCard className='section-card-3' variants={cardVariant} id="section-card2">
                            <CardTitle>ORGANIC</CardTitle>
                            <CardBody>Holistic system which enhances agro-ecosystem health and animal welfare. Uses naturally derived pesticides, and avoids artifical inputs, genetic modification and antibiotics.</CardBody>
                        </SectionCard>


                        <SectionCard className='section-card-3' variants={cardVariant} id="section-card3">
                            <CardTitle>Pasture for Life</CardTitle>
                            <CardBody>Promotes livestock grazing on grass and forage their entire lives.</CardBody>
                        </SectionCard>


                        <SectionCard className='section-card-3' variants={cardVariant} id="section-card4">
                            <CardTitle>Linking
                                environment and
                                Farming (LEAF)</CardTitle>
                            <CardBody>Enables sustainable farming through integrated farm management - a site-specific approach that uses the best of modern technology and traditional methods.</CardBody>
                        </SectionCard>


                        <SectionCard className='section-card-3' variants={cardVariant} id="section-card5">
                            <CardTitle>Permaculture</CardTitle>
                            <CardBody>Tackles how to grow food, build houses and create communities, while minimising environmental impact.</CardBody>
                        </SectionCard>

                    </CardsWrapper>
                )}
            </ContentContainer>

            <BackgroundWrapper>
                <motion.img src={'./GrassCow.svg'} />
            </BackgroundWrapper>

        </SectionWrapper>
    )
}

export default Section3