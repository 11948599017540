import { motion } from 'framer-motion';
import React from 'react'
import { styled } from '../theme.stitches';
import Button from './core/button/Button';

const CookieContainer = styled(motion.div, {
    position: 'fixed',
    bottom: '1rem',
    left: '1rem',
    backgroundColor: 'white',
    zIndex: '9992',

    borderRadius: '1rem',
    boxShadow: 'hsl(206 22% 7% / 35%) 0px 10px 38px -10px, hsl(206 22% 7% / 20%) 0px 10px 20px -15px',
    width: '90vw',
    maxWidth: '30rem',
    maxHeight: '85vh',
    padding: '4rem',

    display: 'flex',
    flexDirection: 'column',

    '@mobile': {
        width: '90vw',
        padding: '3rem',
        boxSizing: 'border-box',

    }
})

const DialogParagraph = styled('p', {
    width: '100%',
    fontFamily: '$primary',
    fontSize: '1.125rem',
    lineHeight: '1.75rem',
    color: '#222222',

    'a': {
        textDecoration: 'underline',
        color: 'black'
    }
});

//Make cookie concent component
const CookieConsent = () => {
    const [cookiesAccepted, setCookiesAccepted] = React.useState(true);

    if (!cookiesAccepted) {
        return null
    }

    return (
        <CookieContainer
            initial={{ opacity: 0, y: -100 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: -100 }}
            transition={{
                duration: 0.5,
                ease: [0.6, 0.05, -0.01, 0.9],
            }}
        >
            <DialogParagraph>
                This website uses cookies to ensure you get the best experience on our website. Please check our <a href="https://sefari.scot/privacy-notice#:~:text=SEFARI%20will%20keep%20your%20personal,stops%20processing%20your%20personal%20data.">privacy policy</a> for more information.

            </DialogParagraph>
            <Button onClick={() => setCookiesAccepted(false)}>Accept</Button>
        </CookieContainer>
    )
}

export default CookieConsent;
