import gsap from 'gsap';

const animateTimelineSection2 = (isMobile: boolean) => {
    
    //Initilize timeline
    const timeline1 = gsap.timeline({
        scrollTrigger: {
            trigger: '.section-2-container',
            start: 'top top',
            end: "+=" + (window.innerHeight * 3),
            scrub: true,
            pin: true,
            id: 'section-2-timeline',
        }
    })

    const titleTimeline = gsap.timeline({
        scrollTrigger: {
            trigger: '.section-2-container',
            start: 'top 70%',
            id: 'section-2-timeline',
            toggleActions: 'play none none reverse',
        }
    })


    //Fade in main titles
    titleTimeline.fromTo('.section-2-title', {
        opacity: 0,
        y: -20,
        duration: 1,
    }, {
        opacity: 1,
        y: 0,
        stagger: .2,
        ease: 'power1.out',
        duration: 1,
    })
    //Fade in green title and icons

    timeline1.fromTo('.section-2-green-title', {
        opacity: 0,
        y: -20,
        duration: 1,
    }, {
        opacity: 1,
        y: 0,
        stagger: .2,
        ease: 'power1.out',
        duration: 1,
    }, '-=1')

    timeline1.fromTo('.green--icon', {
        opacity: 0,
        y: -20,
        duration: 1,
    }, {
        opacity: 1,
        y: 0,
        stagger: .2,
        ease: 'power1.out',
        duration: 1,
    })

    //Fade out green title and icons

    .to('.section-2-green-title', {
        opacity: 0,
        y: -20,
        duration: 1,
        ease: 'power1.out',
    })

    .to('.green--icon', {
        opacity: 0,
        y: -20,
        ease: 'power1.out',
        duration: 1,
    }, '-=1')

    //Fade in yellow title and icons

    .fromTo('.section-2-yellow-title', {
        opacity: 0,
        y: -20,
        duration: 1,

    }, {
        opacity: 1,
        y: 0,
        stagger: .2,
        ease: 'power1.out',
        duration: 1,
    })

    .fromTo('.yellow--icon', {
        opacity: 0,
        y: -20,
        duration: 1,
    }, {
        opacity: 1,
        y: 0,
        stagger: .2,
        ease: 'power1.out',
        duration: 1,
    })

    //Fade out yellow icons and title

    .fromTo('.section-2-yellow-title', {

        opacity: 1,
        y: 0,
        stagger: .2,
        ease: 'power1.out',
        duration: 1,
    }, {
        opacity: 0,
        y: -20,
        duration: 1,
        ease: 'power1.out',
    })

    .to('.yellow--icon', {
        opacity: 0,
        y: -20,
        ease: 'power1.out',
        duration: 1,
    }, '-=1')

    //Fade in orange title and icons

    .fromTo('.section-2-orange-title', {
        opacity: 0,
        y: -20,
        ease: 'power1.out',
        duration: 1,
    }, {
        opacity: 1,
        y: 0,
        duration: 1,
        ease: 'power1.out',
    })

    .fromTo('.orange--icon', { 
        opacity: 0,
        y: -20,
        duration: 1,
    }, {
        opacity: 1,
        y: 0,
        stagger: .2,
        ease: 'power1.out',
        duration: 1,
    })

    //Fade out orange title and icons

    /*
    .fromTo('.section-2-orange-title', {

        opacity: 1,
        y: 0,
        stagger: .2,
        ease: 'power1.out',
        duration: 1,
    }, {
        opacity: 0,
        y: -20,
        duration: 1,
        ease: 'power1.out',
    })

    .to('.orange--icon', {
        opacity: 0,
        y: -20,
        ease: 'power1.out',
        duration: 1,
    }, '-=1')
    */
}

export default animateTimelineSection2;