
import './styles/Global.scss';
import { styled } from './theme.stitches';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { AnimatePresence, motion } from 'framer-motion';
import Introduction from './sections/Introduction';
import Section1 from './sections/Section1';
import Section2 from './sections/Section2';
import Section3 from './sections/Section3';
import Section4 from './sections/Section4';
import Section5 from './sections/Section5';
import Section6 from './sections/Section6';
import Section7 from './sections/Section7';
import Section8 from './sections/Section8';
import { useRecoilState } from 'recoil';
import SectionAtom from './context/SectionContext';
import { useDebouncedCallback } from 'use-debounce';
import Section10 from './sections/Section10';
import Section9 from './sections/Section9';
import useIsMobile from './hooks/useMobile';
import CookieConsent from './components/CookieConsent';

const AppWrapper = styled('div', {
  overflow: 'hidden',
});

const SectionWrappers = styled('div', {
  position: 'relative',
  zIndex: 1
});

const SECTIONS = [
  Section1,
  Section2,
  Section3,
  Section4,
  Section5,
  Section6,
  Section7,
  Section8,
]




function App() {
  //Has the introduction completed
  //set introduction to true after 3s on component mount
  const [introduction, setIntroduction] = useState(true);
  const isMobile = useIsMobile()

  //Recomve the introduction after it has completed
  useEffect(() => {
    setInterval(() => {
      setIntroduction(false);
    }, 2000);
  }, []);

  const appContainerRef = useRef(null)

  return (
    <AppWrapper ref={appContainerRef}>
      <AnimatePresence>
        <CookieConsent key="Cookie-consent" />

        {introduction && (<Introduction key="introduction-item" />)}

        <div className="sections-container" key="section-wrapper">
          <Section1 />
          <Section2 />
          <Section3 />
          <Section4 />
          <Section5 />
          {isMobile && <Section6 />}
          <Section7 />
          <Section8 />
          <Section9 />
          <Section10 appRef={appContainerRef} />
        </div>
      </AnimatePresence>
    </AppWrapper>
  );
}

export default App;
