import gsap from 'gsap';

const generateSection9Animations = (isMobile: boolean) => {

    if (isMobile) return 
    const timeline = gsap.timeline({
        scrollTrigger: {
            trigger: '.section-9-container',
            start: 'top 70%',
            id: 'section-9-timeline',
            toggleActions: 'play none none reverse',
        }
    });

    //Animate in the section titles
    timeline.fromTo('.section-9--title', {
        opacity: 0,
        y: -20,
        duration: 1,
    }, {
        opacity: 1,
        y: 0,
        stagger: .2,
        ease: 'power1.out',
        duration: .5,
    })

    //Ping the background and fade in the icons

    const timeline2 = gsap.timeline({
        scrollTrigger: {
            trigger: '.section-9-container',
            start: 'top top',
            id: 'section-9-timeline',
            end: "+=" + (window.innerHeight * 2),
            toggleActions: 'play none none reverse',
        }
    });

    timeline2.fromTo('.section-9-background', {
        opacity: 0,
        y: -20,
        duration: 1,
    }, {
        opacity: 1,
        y: 0,
        stagger: .2,
        ease: 'power1.out',
        duration: .5,
    })


    .fromTo('.section-9-point', {
        opacity: 0,
        y: -20,
        duration: 1,
    }, {
        opacity: 1,
        y: 0,
        stagger: .2,
        ease: 'power1.out',
        duration: .5,
    }, "-=.5")



}

export default generateSection9Animations;
