import React, { useEffect } from 'react'
import generateSection8Animations from '../animation/Section8';
import Container from '../components/core/container/Container';
import useIsMobile from '../hooks/useMobile';
import { styled } from '../theme.stitches';

const SectionWrapper = styled('div', {
    minHeight: '70vh',
    marginTop: '-1rem',
    backgroundColor: "#6C7E35",
    padding: '2rem 0'
});

const SectionGrid = styled('div', {
    display: "grid",
    gridTemplateColumns: "1fr ",
    gap: "3rem",

    '@mobile': {
        gridTemplateColumns: '1fr'
    }
})

const ChartQuote = styled('div', {
    fontFamily: '$primary',
    fontSize: '1.25rem',
    lineHeight: '2rem',
    fontWeight: '400',
    color: "$white",
    textAlign: 'center',
    width: '70%',
    margin: '0 auto',
    marginBottom: '2rem',

    '@mobile': {
        margin: '2rem auto',
        fontSize: '1.5rem',
        lineHeight: '2.25rem',
        textAlign: 'center'
    }
});

const ChartWrapper = styled('div', {
    'img': {
        width: '100%',
        '@mobile': {
            margin: '2rem 0 0 0',
            transform: 'scale(1.15)'
        }
    }
});

const CommentsWrapper = styled('div', {
    margin: '4rem 0',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    boxSizing: 'border-box',

    color: '$black',
    gap: '3rem',

    '@mobile': {

        marginBottom: '1rem',
        flexDirection: 'column',
        padding: '2.5rem 0',
        gap: '1.25rem',
        backgroundColor: 'transparent'
    }
});

const Comment = styled('div', {
    fontFamily: '$primary',
    fontSize: '.9rem',
    lineHeight: '1.4rem',
    fontWeight: '700',
    color: "$black",
    textAlign: 'center',
    backgroundColor: '#D5CE49',
    borderRadius: '1rem',
    padding: '1.5rem',

    '@mobile': {

        '-webkit-margin-bottom': '2rem',

        fontSize: '1.15em',
        lineHeight: '1.7rem',
    }
});


const Section8 = () => {

    const isMobile = useIsMobile()

    useEffect(() => generateSection8Animations(isMobile), [])

    return (
        <SectionWrapper className='section-8-container'>
            <Container width={'small'}>
                <SectionGrid>
                    <ChartQuote className="section-8-quote">
                        Farmers were asked ‘is it possible to produce healthy food on the same piece of land, whilst protecting the environment and mitigating and adapting to climate change?’
                    </ChartQuote>

                    {isMobile ? (
                        <ChartWrapper className="section-8-chart">
                            <img src="/Section8ChartMobile.svg" alt="Section 8 Chart" />
                        </ChartWrapper>
                    ) : (
                        <ChartWrapper className="section-8-chart">
                            <img src="/Section8Chart.svg" alt="Section 8 Chart" />
                        </ChartWrapper>
                    )}

                </SectionGrid>

                <CommentsWrapper>
                    <Comment className="section-8-comment">
                        We need more diversified food and farming systems that protect the environment and are resistant to external shocks
                    </Comment>
                    <Comment className="section-8-comment">
                        We are disconnected from local food production, local food traditions and sense of place
                    </Comment>
                    <Comment className="section-8-comment">
                        We can produce healthy food on the same piece of land whilst protecting the environment and mitigating climate change
                    </Comment>
                </CommentsWrapper>

            </Container>
        </SectionWrapper>
    )
}

export default Section8