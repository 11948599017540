

import gsap from 'gsap';

const generateSection8Animations = (isMobile: boolean) => {
    const timeline = gsap.timeline({
        scrollTrigger: {
            trigger: '.section-8-container',
            start: 'top 80%',
            id: 'section-8-timeline',
            toggleActions: 'play none none reverse',
        }
    });

    timeline.fromTo('.section-8-quote', {
        opacity: 0,
        y: -20,
        duration: 1,
    }, {
        opacity: 1,
        y: 0,
        stagger: .2,
        ease: 'power1.out',
        duration: 1,
    },"-=.25")

    .fromTo('.section-8-chart', {
        opacity: 0,
        y: 100,
        duration: 1,
    }, {
        opacity: 1,
        y: 0,
        ease: 'power1.out',
        duration: 1,
    }, "-=1")

    .fromTo('.section-8-comment', {
        opacity: 0,
        y: 100,
        duration: 1,
    }, {
        opacity: 1,
        y: 0,
        stagger: .2,
        ease: 'power1.out',
        duration: 1,
    }, "-=1")

    //Animate in the section titles

}

export default generateSection8Animations;