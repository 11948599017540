

import { styled } from "../../../theme.stitches";

const BASE_WIDTH = 1200 / 16; // 1025px / 16px = REM value

const Container = styled('div', {
    height: 'auto',
    position: 'relative',
    variants: {
        width: {
            offset: {
                margin: '0 auto',
                marginLeft: 'calc((100% - ' + BASE_WIDTH + 'rem) / 2)',
                '@mobile': {
                    width: '85%',
                    margin: '0 auto',
                },
            },
            default: {
                width: BASE_WIDTH + 'rem',
                margin: '0 auto',
                '@mobile': {
                    width: '85%',
                    margin: '0 auto',
                },
            },
            small: {
                width: 950 / 16 + 'rem',
                margin: '0 auto',
                '@mobile': {
                    width: '85%',
                    margin: '0 auto',
                },
            },
            large: {
                width: '92%',
                margin: '0 auto',
                '@mobile': {
                    width: '100%',
                    margin: '0 auto',
                },
            },
            full: {
                width: '100%',
                
            }
        }
    },
    defaultVariants: {
        width: 'default'
    }
});

export default Container