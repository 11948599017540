import gsap from 'gsap';

const isMobileRegex = () => {
    if (
        navigator.userAgent.match(
            /(android|iphone|ipod|blackberry|iemobile|bolt|symbian|windows)/i
        )
    ) {
        return true;
    } else {
        return false;
    }
}

const generateSection5Animations = (isMobile: boolean) => {

    if (isMobile) {
        return
    }

    console.log(isMobile);
    

    const timeline = gsap.timeline({
        scrollTrigger: {
            trigger: '.section-5-container',
            start: 'top top',
            toggleActions: 'play none none reverse',
        }
    })

    console.log('called');
    

    timeline.fromTo('.section-5--title', {
        opacity: 0,
        y: -20,
        duration: 1,
    }, {
        opacity: 1,
        y: 0,
        stagger: .2,
        ease: 'power1.out',
        duration: 1,
    })
    
    const timeline2 = gsap.timeline({
        scrollTrigger: {
            trigger: '.section-5-container',
            start: 'top top',
            pin: true,
            id: 'section-5-timeline',
            scrub: true,
            end: "+=" + (window.innerHeight * 4),
        }
    })

        .fromTo('.section-5-chart-1', {
            opacity: 0,
            y: 100,
            duration: 1,
        }, {
            opacity: 1,
            y: 0,
            ease: 'power1.out',
            duration: 1,
        })

        .fromTo('.section-5--title', {
            opacity: 1,
            y: 0,
            duration: 1,
        }, {
            opacity: 0,
            y: -20,
            stagger: .2,
            ease: 'power1.out',
            duration: 1,
        })

        .fromTo('.section-5-chart-1', {
            opacity: 1,
            y: 0,
            duration: 1,
        }, {
            opacity: 0,
            y: 100,
            ease: 'power1.out',
            duration: 1,
        }, "-=.9")
    
        .fromTo('.section-6--title', {
            opacity: 0,
            y: -20,
            duration: 1,
        }, {
            opacity: 1,
            y: 0,
            stagger: .2,
            ease: 'power1.out',
            duration: 1,
        })
    
        .fromTo('.section-6-chart', {
            opacity: 0,
            y: 100,
            duration: 1,
        }, {
            opacity: 1,
            y: 0,
            ease: 'power1.out',
            duration: 1,
        })







}

export default generateSection5Animations;