import gsap from 'gsap';

const generateSection7Animations = (isMobile: boolean) => {
    const timeline = gsap.timeline({
        scrollTrigger: {
            trigger: '.section-7-container',
            start: 'top 70%',
            id: 'section-7-timeline',
            toggleActions: 'play none none reverse',
        }
    });

    //Animate in the section titles
    timeline.fromTo('.section-7--title', {
        opacity: 0,
        y: -20,
        duration: 1,
    }, {
        opacity: 1,
        y: 0,
        stagger: .2,
        ease: 'power1.out',
        duration: .5,
    })

    .fromTo('.section-7-card', {
        opacity: 0,
        y: -60,
        duration: 1,
    }, {
        opacity: 1,
        y: 0,
        stagger: .2,
        duration: .4,
    }, "-=.5")
}

export default generateSection7Animations;