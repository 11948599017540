import { motion } from 'framer-motion'
import React, { useEffect } from 'react'
import animateTimelineSection4 from '../animation/Section4'
import Animate from '../components/Animate'
import Container from '../components/core/container/Container'
import useIsMobile from '../hooks/useMobile'
import { styled } from '../theme.stitches'


const SectionWrapper = styled('div', {
  height: 'max(110vh, 60rem)',
  '@mobile': {
    height: 'auto',
  }
})

const TitleContainer = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  marginTop: '6.5rem',
})

const MainTitle = styled(motion.span, {
  fontFamily: '$primary',
  fontSize: '2.5rem',
  textAlign: 'center',
  lineHeight: '2.8rem',
  fontWeight: '700',
  marginBottom: '2rem',
  color: '#222222',
})

const SubTitle = styled(motion.span, {
  fontFamily: '$primary',
  fontSize: '2rem',
  textAlign: 'center',
  lineHeight: '2.8rem',
  fontWeight: '600',
  color: '#8C54A2',
  marginBottom: '3.75rem',

})

const SectionGrid = styled('div', {
  display: 'grid',
  //50 / 50 grid 
  gridTemplateColumns: '7fr 4fr',
  gap: '5rem',
  marginBottom: '6.6rem',

  '@mobile': {
    marginBottom: '3rem',
    gridTemplateColumns: '1fr',
    gap: '2rem',
  }
});

const ChartWrapper = styled('div', {
  '@mobile': {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  'img': {
    width: '100%',
    '@mobile': {
      width: '80%',
    }
  }
});

const ChartInformation = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',

});

const InfoBlock = styled(motion.div, {
  padding: '2rem',
  backgroundColor: '#8C54A2',
  borderRadius: '0.25rem',
  width: '100%',
  color: "$white",
  fontSize: '1.25rem',
  fontWeight: '300',
  lineHeight: '1.9rem',
  fontFamily: '$primary',
  boxSizing: 'border-box',
  marginBottom: '2rem',

  '@mobile': {
    '-webkit-margin-bottom': '2rem',
  }

});

const PieChartTable = styled('div', {
  display: 'grid',
  gridTemplateColumns: '1fr 1fr',
  gap: '1rem',
  margin: '2rem 0'

});

const PieValue = styled(motion.div, {
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
})

const PieLabel = styled('span', {
  fontFamily: '$primary',
  width: '100%',

  fontSize: '.75em',
  lineHeight: '1.5rem',
  color: 'black',
  marginLeft: '.5rem',
  whiteSpace: 'nowrap',
});

const PiePill = styled('div', {
  width: '1.5rem',
  height: '1.5rem',
  borderRadius: '.4rem',

  variants: {
    color: {
      blue1: {
        backgroundColor: '#96C5C4',
      },
      blue2: {
        backgroundColor: '#19B2E7',
      },
      red: {
        backgroundColor: '#E6295E',
      },
      grey: {
        backgroundColor: '#96C5C4',
      },
      brown: {
        backgroundColor: '#BD7442',
      },
      yellow: {
        backgroundColor: '#D4CF48',
      },
      green: {
        backgroundColor: '#A2BE4A',
      },
      purple: {
        backgroundColor: '#8C54A2',
      }
    }
  }
});

const chartAnimation = {
  initial: {
    opacity: 0,
    x: -40,
  },
  animate: {
    opacity: 1,
    x: 0,
    transition: {
      duration: 0.8,
      ease: [0.6, 0.05, -0.01, 0.9]
    }
  }
}

const barsAnimation = {
  initial: {
    opacity: 0,
    x: 40,
  },
  animate: {
    opacity: 1,
    x: 0,
    transition: {
      staggerChildren: 0.2,
      duration: 0.8,
      ease: [0.6, 0.05, -0.01, 0.9]
    }
  }
}

const Section4 = () => {

  const isMobile = useIsMobile();

  useEffect(() => animateTimelineSection4(isMobile), [isMobile]);

  return (
    <SectionWrapper className="section-4-container">
      <Container className='section-4-container-2'>
        <TitleContainer>
          <MainTitle className="section-4--title" variants={Animate.variants}>Summary of findings from <br />SEFARI’s 2021 study</MainTitle>
          <SubTitle className="section-4--title" variants={Animate.variants}>Which methods are Scottish farmers<br /> currently using?</SubTitle>
        </TitleContainer>

        <SectionGrid>
          {isMobile ? (
            <>
              <ChartWrapper className="section-4-chart">
                <img src={'./Section4MobilePie.svg'} alt='Information chart' />
              </ChartWrapper>

              <PieChartTable>
                <PieValue className="section-4-pill" variants={barsAnimation}>
                  <PiePill color={'blue1'} />
                  <PieLabel>11% Organic</PieLabel>
                </PieValue>
                <PieValue className="section-4-pill" variants={barsAnimation}>
                  <PiePill color={'blue2'} />
                  <PieLabel>31% Conventional</PieLabel>
                </PieValue>
                <PieValue className="section-4-pill" variants={barsAnimation}>
                  <PiePill color={'red'} />
                  <PieLabel>21% Regenerative</PieLabel>
                </PieValue>
                <PieValue className="section-4-pill" variants={barsAnimation}>
                  <PiePill color={'grey'} />
                  <PieLabel>3% Permaculture</PieLabel>
                </PieValue>
                <PieValue className="section-4-pill" variants={barsAnimation}>
                  <PiePill color={'brown'} />
                  <PieLabel>3% LEAF</PieLabel>
                </PieValue>
                <PieValue className="section-4-pill" variants={barsAnimation}>
                  <PiePill color={'yellow'} />
                  <PieLabel>6% Pasture for life</PieLabel>
                </PieValue>
                <PieValue className="section-4-pill" variants={barsAnimation}>
                  <PiePill color={'green'} />
                  <PieLabel>17% Agroecological</PieLabel>
                </PieValue>
                <PieValue className="section-4-pill" variants={barsAnimation}>
                  <PiePill color={'purple'} />
                  <PieLabel>9% Other</PieLabel>
                </PieValue>
              </PieChartTable>
            </>

          ) : (
            (

              <ChartWrapper className="section-4-chart">
                <img src={'./Section4Chart.svg'} alt='Information chart' />
              </ChartWrapper>


            )
          )}
          <ChartInformation>
            <InfoBlock className="section-4-bar" variants={barsAnimation}>
              60% already use an agroecological approach
            </InfoBlock>
            <InfoBlock className="section-4-bar" variants={barsAnimation}>
              New farming entrants are more likely to adopt agroecological farming          </InfoBlock>
            <InfoBlock className="section-4-bar" variants={barsAnimation}>
              New knowledge acquisition differs between genders
            </InfoBlock>
          </ChartInformation>
        </SectionGrid>
      </Container>
    </SectionWrapper>
  )
}

export default Section4