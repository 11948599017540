import React from "react";
import Button, { ButtonVariants } from "../button/Button";

type AnchorVariants = React.HTMLAttributes<HTMLAnchorElement> & ButtonVariants & { href: string }

const Anchor = React.forwardRef<React.ElementRef<'a'>, AnchorVariants>((props, ref) => {
    return (
        <Button target="_blank" css={{ display: 'flex' }} as="a" style="anchor" ref={ref} {...props} />
    )
});

Anchor.displayName = 'Anchor';

export default Anchor;