import { motion } from 'framer-motion';
import React, { useEffect } from 'react'
import Animate from '../components/Animate';
import Container from '../components/core/container/Container';
import Flex from '../components/core/flex/Flex';
import { H2, H4, H5 } from '../components/core/text';
import { styled } from '../theme.stitches';
import { gsap } from 'gsap';
import animateTimelineSection2 from '../animation/Section2';
import useIsMobile from '../hooks/useMobile';

const SectionContainer = styled('div', {
    position: 'relative',
    backgroundColor: '#222222',
    padding: '2rem 0',
    minHeight: '100vh',
    boxSizing: 'border-box',
    overflow: 'hidden',

    "@smallDesktop": {
        padding: '2rem 0'
    },
    "@mobile": {
        height: '110vh',
    }
});


const SectionInnerWrapper = styled('div', {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',

});

const SectionTitleContainer = styled(Flex, {

})

const IconSectionTitle = styled(motion.span, {
    fontSize: '2rem',
    lineHight: '2.2rem',
    fontFamily: '$primary',
    color: '#A6BF49',
    margin: '3rem 0 5rem 0',
    textAlign: 'center',



    '@smallDesktop': {
        margin: '1rem 0 3rem 0'
    },


    '@mobile': {
        margin: '1rem 0 2rem 0',

        fontSize: '1.25rem',
        lineHight: '1.5rem',
        width: '75%'
    },

    variants: {
        colours: {
            green: {
                color: '#A6BF49',
            },
            yellow: {
                color: '#D4CE48'
            },
            orange: {
                color: '#BD7442'
            }
        }
    },
    defaultVariants: {
        colours: 'green'
    }
})


const IconsGridOne = styled('div', {
    display: 'grid',
    gridTemplateColumns: 'repeat(3, 1fr)',
    gridGap: '3rem',
    marginBottom: '2rem',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '0 12rem',

    '@mobile': {
        padding: 0,
        gridTemplateColumns: 'repeat(2, 1fr)',
        gridGap: '1rem',
        rowGap: '2rem',
    },

    '@mobileSmall': {
        padding: '0 5rem',
        gridGap: '.6rem'
    }
})

const IconsGridTwo = styled('div', {
    display: 'grid',
    gridTemplateColumns: 'repeat(2, 1fr)',
    gridGap: '1rem',
    marginBottom: '2rem',
    padding: '0 16rem',
    alignItems: 'center',
    justifyContent: 'center',

    '@mobile': {
        padding: 0,
        gridTemplateColumns: 'repeat(1, 1fr)',
        gridGap: '1rem',
        rowGap: '2rem',
    }


})

const SectionTitle = styled(motion.span, {
    fontSize: '2.5rem',
    lineHight: '3rem',
    fontFamily: '$primary',
    color: 'white',

    '@mobile': {
        fontSize: '1.75rem',
        lineHight: '2rem',
    }
});


const SectionSubTitle = styled(motion.span, {
    fontSize: '5rem',
    lineHight: '5.73rem',
    fontFamily: '$primary',
    color: 'white',
    fontWeight: '900',


    '@mobile': {
        fontSize: '1.95rem',
        lineHight: '2.2rem',
    }
});

const IconWrapper = styled('div', {
    position: 'absolute',
    top: '0',
    width: '100%',

    "@smallDesktop": {
        marginTop: 0
    }
});

const BackgroundWrapper = styled('div', {
    position: 'absolute',
    top: '10%',
    height: '100%',
    width: '100%',
    zIndex: 1,
    opacity: .6,

    'img': {
        width: '100%',
        position: 'absolute',
        top: '0',
    }
});

const ContentWrapper = styled('div', {
    position: 'relative',
    zIndex: 2,
});

const IconsContainer = styled('div', {
    position: 'relative',
})



const Section2 = () => {

    const isMobile = useIsMobile()

    useEffect(() => animateTimelineSection2(isMobile), [isMobile]);

    return (
        <SectionContainer className="section-2-container">
            <ContentWrapper>

                <Container>
                    <SectionTitleContainer direction={'column'} alignItemsCenter>
                        <SectionTitle className="section-2-title" variants={Animate.variants}>The 10 elements of</SectionTitle>
                        <SectionSubTitle className="section-2-title" variants={Animate.variants}>AGROECOLOGY</SectionSubTitle>
                    </SectionTitleContainer>
                </Container>
                <Container>
                    <IconsContainer>

                        <IconWrapper>
                            <Flex justifyCenter>
                                <IconSectionTitle className="section-2-green-title" variants={Animate.variants}>Characteristics of agroecological systems</IconSectionTitle>
                            </Flex>
                            <IconsGridOne>
                                <Icon key="i1" title="Diversity" src="element-diversity.svg" />
                                <Icon key="i2" title="Co-creation and sharing knowledge" src="element-co-creation.svg" />
                                <Icon key="i3" title="Synergies" src="element-synergy.svg" />
                                <Icon key="i4" title="Efficiency" src="element-efficiency.svg" />
                                <Icon key="i5" title="Recycling" src="element-recycling.svg" />
                                <Icon key="i6" title="Resilience" src="element-resilience.svg" />
                            </IconsGridOne>
                        </IconWrapper>
                        <IconWrapper>
                            <Flex justifyCenter>
                                <IconSectionTitle className="section-2-yellow-title" variants={Animate.variants} colours={'yellow'}>Social and cultural aspects</IconSectionTitle>
                            </Flex>
                            <IconsGridTwo>
                                <Icon key="i7" title="Diversity" src="element-humansocial.svg" colour='yellow' />
                                <Icon key="i8" title="Co-creation and sharing knowledge" src="element-culturefood.svg" colour='yellow' />
                            </IconsGridTwo>
                        </IconWrapper>
                        <IconWrapper>
                            <Flex justifyCenter>
                                <IconSectionTitle className="section-2-orange-title" variants={Animate.variants} colours={'orange'}>Enabling environment</IconSectionTitle>
                            </Flex>
                            <IconsGridTwo>
                                <Icon key="i9" title="Diversity" src="element-respinsiblegov.svg" colour='orange' />
                                <Icon key="i10" title="Co-creation and sharing knowledge" src="element-circulareconomy.svg" colour='orange' />
                            </IconsGridTwo>
                        </IconWrapper>
                    </IconsContainer>
                </Container>
            </ContentWrapper>
            <BackgroundWrapper>
                <img src='wave.png' alt='wave' />
            </BackgroundWrapper>
        </SectionContainer>
    )
}

const IconContainer = styled(motion.div, {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',

    'img': {
        width: '8.75rem',

        "@smallDesktop": {
            width: '8rem'
        },

        '@mobile': {
            width: '6rem',
        },

        '@mobileSmall': {
            width: '5rem'
        }
    }
});


const IconTitle = styled('span', {
    fontSize: '1.25rem',
    lineHight: '2rem',
    fontFamily: '$primary',
    color: '#A6BF49',
    textAlign: 'center',
    marginTop: '1.5rem',
    width: '12rem',
    height: '2rem',



    '@mobile': {
        fontSize: '.85rem',
    },

    variants: {
        colours: {
            green: {
                color: '#A6BF49',
            },
            yellow: {
                color: '#D4CE48'
            },
            orange: {
                color: '#BD7442'
            }
        }
    },
    defaultVariants: {
        colours: 'green'
    }
})



const Icon = ({
    title,
    src,
    colour = 'green'
}: {
    title: string,
    src: string,
    colour?: 'green' | 'yellow' | 'orange',
}) => {

    return (
        <IconContainer className={colour + "--icon"} variants={Animate.variants}>
            <img src={"/" + src} alt={title} />
            <IconTitle colours={colour}>{title}</IconTitle>
        </IconContainer>
    )

}

export default Section2