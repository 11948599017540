import gsap from 'gsap';

const animateTimelineSection4 = (isMobile: boolean) => {

    if (isMobile) return;
    const timelineTitle = gsap.timeline({
        scrollTrigger: {
            trigger: '.section-4-container',
            start: 'top 65%',
            id: 'section-4-timeline',
            toggleActions: 'play none none reverse',
        }
    })

    timelineTitle.fromTo('.section-4--title', {
        opacity: 0,
        y: -50,
        duration: 1
    }, {
        opacity: 1,
        stagger: .2,
        y: 0,
        ease: 'power1.out',
        duration: 1,
    })



    const timeline = gsap.timeline({
        scrollTrigger: {
            trigger: '.section-4-container',
            start: 'top 50%',
            id: 'section-4-timeline',
            end: "+=" + (window.innerHeight * 3),
            toggleActions: 'play none none reverse',
        }
    })

    timeline.fromTo('.section-4-chart', {
        opacity: 0,
        x: -50,
        duration: 1
    }, {
        opacity: 1,
        x: 0,
        ease: 'power1.out',
        duration: 1,
    })
        
    if (isMobile) {
        timeline.fromTo('.section-4-pill', {
            opacity: 0,
            x: -50,
            duration: 1
        }, {
            opacity: 1,
            x: 0,
            stagger: .05,
            ease: 'power1.out',
            duration: 1,
        })
    }

    timeline.fromTo('.section-4-bar', {
        opacity: 0,
        x: 50,
        duration: 1,
    }, {
        opacity: 1,
        x: 0,
        ease: 'power1.out',
        duration: 1,
        stagger: 0.3
    }, '-=1')



    

}

export default animateTimelineSection4