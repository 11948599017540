

import { useEffect, useRef } from 'react'

import Button from '../components/core/button/Button'
import Container from '../components/core/container/Container'
import Flex from '../components/core/flex/Flex'
import { keyframes, styled } from '../theme.stitches'

import { ReactComponent as Grasslands } from '../assets/section1Grass.svg'
import { Dialog, DialogClose, DialogContent, DialogTrigger } from '../components/core/dialog/Dialog'
import { DialogTitle, DialogDescription } from '@radix-ui/react-dialog'
import { Cross2Icon } from '@radix-ui/react-icons'
import Animate from '../components/Animate'
import { gsap } from 'gsap'
import { ScrollTrigger } from 'gsap/all'

import { motion } from 'framer-motion'
import SectionAtom from '../context/SectionContext'
import { useRecoilState } from 'recoil'
import { log } from 'console'
import animateTimelineSection1 from '../animation/Section1'

gsap.registerPlugin(ScrollTrigger)

const BackgroundAnimation = keyframes({
    '0%': {
        background: 'white',
    },
    '100%': {
        background: 'linear-gradient(180deg, rgba(60, 173, 255, 0) 15.76%, #3CADFF 100%)'
    }
}
)

const SectionWrapper = styled(motion.div, {
    position: 'relative',
    height: 'max(100vh, 60rem)',
    background: 'linear-gradient(180deg, rgba(60, 173, 255, 0) 15.76%, #3CADFF 100%)',

    '@mobile': {
        height: '80vh',
    }
});

const ButtonWrapper = styled('div', {
    display: 'flex',
    justifyContent: 'center',
    gap: '1rem',

    '@mobile': {
        'button': {
            width: '100%',
        },
        gap: 0,
        alignItems: 'center',
        flexDirection: 'column',
    }
})

const TextContainer = styled(Flex, {
    gap: '1rem',
    marginBottom: '2rem',
})

const TitleContainer = styled(Container, {
    marginTop: '8rem !important',
    zIndex: '20',
})

const BackgroundWrapper = styled('div', {
    position: 'relative',
    width: '100%',
    zIndex: '10',
    marginTop: '2rem',
    '@mobile': {
        bottom: '20%',
        position: 'absolute',
    },
    ['img']: {
        position: 'relative',
        bottom: '0',
        width: '100%',

        '@mobile': {
            bottom: '11rem',
        }
    }
})

const MarqueeContainer = styled('div', {

    position: 'relative',
    width: '100%',
    padding: '4rem 0',
    marginTop: '-.5rem',
    backgroundColor: '#6C7E35',
    boxSizing: 'border-box',
    zIndex: '10',
    '@mobile': {
        bottom: 0,
        position: 'absolute',
    },
});
const MarqueeAnimation = keyframes({
    '0%': {
        transform: 'translate3d(0, 0, 0)',
    },
    '100%': {
        transform: 'translate3d(-50%, 0, 0)',
    }
}
)

const MarqueeWrapper = styled('div', {
    //create css marquee effect
    position: 'relative',
    width: '100%',
    overflow: 'hidden',
    display: 'inline-block',
});

const MarqueeInner = styled('div', {
    width: 'fit-content',
    display: 'flex',
    position: 'relative',
    animation: `${MarqueeAnimation} 30s linear infinite`,
    cursor: 'pointer',
});

const MainTitle = styled(motion.span, {
    fontFamily: '$primary',
    fontSize: '4.5rem',
    fontWeight: '900',
    padding: '0 2rem',

    '@mobile': {
        fontSize: '2rem',
        lineHeight: '2.3rem',
    }
});

const Subtitle = styled(motion.span, {
    fontFamily: '$primary',
    fontSize: '2.5rem',
    fontWeight: '400',
    marginBottom: '2rem',

    '@mobile': {
        fontSize: '2rem',
        lineHeight: '2.3rem',
    }
});

const MarqueeText = styled('span', {
    fontFamily: '$primary',
    fontSize: '2.5rem',
    fontWeight: '400',
    whiteSpace: 'nowrap',
    color: 'white !important',
    padding: '0 .5rem',


    '@mobile': {
        fontSize: '1.125rem',
        lineHeight: '1.35rem',
    }
});

const IconButton = styled('button', {
    all: 'unset',
    fontFamily: 'inherit',
    borderRadius: '100%',
    height: 25,
    width: 25,
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: "black",
    position: 'absolute',
    top: 10,
    right: 10,

    '&:hover': { backgroundColor: '$grey2' },
    '&:focus': { boxShadow: `0 0 0 2px $grey9` },

    '@mobile': {
        top: 20,
        right: 20,
        'svg': {
            height: '100%',
            width: '100%',
        }
    }
});

const DialogParagraph = styled('p', {
    fontFamily: '$primary',
    fontSize: '1.125rem',
    lineHeight: '1.75rem',
    color: '#222222',
});

const DialogGrid = styled('div', {
    display: 'grid',
    gridTemplateColumns: 'repeat(2, 1fr)',
    gridGap: '1rem',

    '@mobile': {
        gridTemplateColumns: 'repeat(1, 1fr)',
    }
});

const Highlight = styled('span', {
    color: '#29A4CD',
});

const MarqueeTitle = styled('span', {
    marginBottom: '1rem',
    fontFamily: '$primary',
    fontSize: '2.5rem',
    fontWeight: '900',
    whiteSpace: 'nowrap',
    color: 'white !important',
    padding: '0 2rem',

    '@mobile': {
        fontSize: '1.125rem',
        lineHeight: '1.35rem',
    }
})

const BottomWrapper = styled('div', {
    position: 'absolute',
    width: '100%',
    bottom: '0',
});


const Section1 = () => {

    //Call and setup GSAP timeline with ScrollTrigger
    useEffect(() => animateTimelineSection1(), []);

    return (
        <SectionWrapper className="section-wrapper-1">
            <TitleContainer>
                <TextContainer justifyCenter alignItemsCenter direction={'column'}>
                    <MainTitle className="section-1--title">AGROECOLOGICAL</MainTitle>
                    <Subtitle className="section-1--title">Farming in Scotland</Subtitle>
                </TextContainer>

                <ButtonWrapper>
                    <Dialog>
                        <DialogTrigger asChild>
                            <Button className="section-1--button">What is Agroecological Farming?</Button>
                        </DialogTrigger>
                        <DialogContent >
                            <DialogParagraph>
                                <Highlight>Agroecological</Highlight> farming uses ecological and social principles to optimise links between plants, animals, humans, and the environment, to create a fair and sustainable food system.
                                It aims to enhance natural processes and can include organic farming, integrated farm management and agroforestry.
                            </DialogParagraph>
                            <DialogClose asChild>
                                <IconButton>
                                    <Cross2Icon />
                                </IconButton>
                            </DialogClose>
                        </DialogContent>
                    </Dialog>
                    <Dialog>
                        <DialogTrigger asChild>
                            <Button className="section-1--button">What is Conventional Farming?</Button>
                        </DialogTrigger>
                        <DialogContent >
                            <DialogParagraph>
                                <Highlight>Conventional</Highlight> farming is perceived to rely on external inputs to maximise yields (rather than intrinsic natural processes) but there is no universal definition.
                                It can include aspects of agroecology.
                            </DialogParagraph>
                            <DialogClose asChild>
                                <IconButton>
                                    <Cross2Icon />
                                </IconButton>
                            </DialogClose>
                        </DialogContent>
                    </Dialog>
                </ButtonWrapper>
            </TitleContainer>

            <BottomWrapper className="section-1-bottom-wrapper">
                <BackgroundWrapper className="section-1--background">
                    <img src="section1Grass.svg" alt="Grasslands" />
                </BackgroundWrapper>
                <MarqueeContainer className="section-1--marquee">
                    <Container>
                        <Flex justifyCenter>
                            <MarqueeTitle>Challenges in farming today</MarqueeTitle>
                        </Flex>
                    </Container>

                    <MarqueeWrapper>
                        <MarqueeInner>
                            <MarqueeText>Conserving Biodiversity • Storing Carbon • Producing affordable food sustainably • Changing Climate</MarqueeText>
                            <MarqueeText>• Conserving Biodiversity • Storing Carbon • Producing affordable food sustainably • Changing Climate</MarqueeText>
                        </MarqueeInner>
                    </MarqueeWrapper>
                </MarqueeContainer>
            </BottomWrapper>

        </SectionWrapper>
    )
}

export default Section1

/*

            */