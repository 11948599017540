import { Cross2Icon } from '@radix-ui/react-icons';
import { motion } from 'framer-motion';
import React, { useEffect } from 'react'
import generateSection9Animations from '../animation/Section9';
import Container from '../components/core/container/Container';
import useIsMobile from '../hooks/useMobile';
import { styled } from '../theme.stitches'
import { Dialog, DialogClose, DialogContent, DialogTrigger } from '../components/core/dialog/Dialog'
import ScrollMore from '../components/ScrollMore';

const SectionWrapper = styled('div', {
    backgroundColor: '#222222',
    boxSizing: 'content-box',
    height: 1400 / 16 + 'rem',
    position: 'relative',
    overflow: 'hidden',
    '@mobile': {
        height: 800 / 16 + 'rem',
        padding: '0'
    }
})

const TitleContainer = styled('div', {
    marginBottom: '4rem',
    paddingTop: '5rem',
    position: 'relative',
    zIndex: 200,
});

const Title = styled('h3', {
    fontFamily: '$primary',
    fontSize: '2.5rem',
    lineHeight: '3rem',
    fontWeight: '400',
    color: '$white',
    textAlign: 'center',
    marginBottom: '1rem',
    margin: 0,
    '@mobile': {
        fontSize: '1rem',
        lineHeight: '1.5rem',
    }
});

const SubTitle = styled('h3', {
    fontFamily: '$primary',
    fontSize: '5rem',
    lineHeight: '5.5rem',
    fontWeight: '900',
    color: '$white',
    textAlign: 'center',
    margin: '.8rem 0',
    textTransform: 'uppercase',
    '@mobile': {
        fontSize: '1.75rem',
        lineHeight: '2rem',
    }
});

const Sub2Title = styled('h3', {
    fontFamily: '$primary',
    fontSize: '2.5rem',
    lineHeight: '3rem',
    fontWeight: '400',
    color: '$white',
    textAlign: 'center',
    marginBottom: '1rem',
    margin: 0,
    '@mobile': {
        fontSize: '1rem',
        lineHeight: '1.5rem',
    }
});

const BackgroundWrapper = styled('div', {
    position: 'absolute',
    width: '100%',
    height: '100%',
    top: 0,
    zIndex: 1,
    '@mobile': {
        top: 'auto',
        bottom: 0,
    },
    'img': {
        width: '100%',
        bottom: 0,
        position: 'absolute',
        opacity: .7,
        '@mobile': {
            position: 'absolute',
            bottom: 0,
            transform: 'scale(1.75) translateY(-20%) translateX(5%)',
            opacity: '.5',
        }
    }
})
const InformationGrid = styled(motion.div, {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    gap: '6rem',
    rowGap: '10rem',
    position: 'relative',
    zIndex: 2,

    '@mobile': {
        marginLeft: '3.5rem',
        marginRight: '3.5rem',
        gridTemplateColumns: '1fr 1fr 1fr 1fr',
        gap: '4rem',
    }
});

const InformationPoint = styled('div', {
    margin: '0 auto',
    borderRadius: '9999px',
    backgroundColor: ' rgba(14, 185, 228, 0.9)',
    padding: '2rem',
    boxSizing: 'border-box',
    height: 340 / 16 + 'rem',
    width: 340 / 16 + 'rem',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    transition: 'all .3s ease-in-out',
    cursor: 'pointer',

    'img': {
        height: '10rem'
    }

});

const InformationPointTitle = styled('span', {
    fontFamily: '$primary',
    fontSize: '1.5rem',
    lineHeight: '2rem',
    fontWeight: '700',
    color: 'white',
    textAlign: 'center',

    '@mobile': {
    }
});

const InformationPointOutter = styled('div', {
    width: '100vw',
    height: '18rem',
    scrollSnapAlign: "center",
});



const SnapWrapper = styled('div', {
    marginTop: '6.5rem',
    padding: '1rem 0',
    overflowX: 'scroll',
    scrollSnapType: 'x mandatory',
    scrollbarWidth: 'none',

    '-ms-overflow-style': 'none',
    '&::-webkit-scrollbar': {
        display: 'none',
    },

    '@mobile': {
        height: '25rem'
    }
});

const ScrollPointsContainer = styled('div', {
    position: 'absolute',
    top: '25%',
    left: '50%',
    transform: 'translateX(-50%)',
    display: 'flex',
    marginTop: '3rem',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 1000,
    width: '100%'
});

const ScrollPointer = styled('div', {
    width: '1.5rem',
    height: '1.5rem',
    borderRadius: '9999px',
    backgroundColor: '$white',
    margin: '0 .5rem',
    transition: 'all .3s ease-in-out',
    cursor: 'pointer',
    '&:hover': {
        transform: 'scale(1.1)',
    },
    variants: {
        active: {
            true: {
                backgroundColor: '$primary',
            }
        }
    }
});

const IconButton = styled('button', {
    all: 'unset',
    fontFamily: 'inherit',
    borderRadius: '100%',
    height: 25,
    width: 25,
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: "black",
    position: 'absolute',
    top: 10,
    right: 10,

    '&:hover': { backgroundColor: '$grey2' },
    '&:focus': { boxShadow: `0 0 0 2px $grey9` },

    '@mobile': {
        top: 20,
        right: 20,
        'svg': {
            height: '100%',
            width: '100%',
        }
    }
});

const InfoPointText = styled('div', {
    fontFamily: '$primary',
    fontSize: '1rem',
    lineHeight: '1.2rem',
    fontWeight: '400',
    color: '$white',
    textAlign: 'center',
    borderRadiusBottom: '9999px',
    margin: "0 auto",
    width: '80%'
});


const DialogParagraph = styled('p', {
    fontFamily: '$primary',
    fontSize: '1.125rem',
    lineHeight: '1.75rem',
    color: '#222222',
});

const DialogGrid = styled('div', {
    display: 'grid',
    gridTemplateColumns: 'repeat(2, 1fr)',
    gridGap: '1rem',

    '@mobile': {
        gridTemplateColumns: 'repeat(1, 1fr)',
    }
});

const Highlight = styled('span', {
    color: '#29A4CD',
});


const Section9 = () => {

    const isMobile = useIsMobile();
    const [swipeIndex, setSwipeIndex] = React.useState(0);
    const scrollContainerRef = React.useRef<HTMLDivElement>(null);

    //scroll scrollContainer to index of point clicked
    const scrollToIndex = (index: number) => {
        if (scrollContainerRef.current) {
            scrollContainerRef.current.scroll({
                left: swipeIndex > index ? scrollContainerRef.current.clientWidth : -scrollContainerRef.current.clientWidth,
                behavior: 'smooth'
            });
        }
        setSwipeIndex(index);
    }

    useEffect(() => {
        console.log(swipeIndex);
    }, [swipeIndex]);

    useEffect(() => {
        generateSection9Animations(isMobile)
    }, [isMobile])

    return (
        <SectionWrapper className='section-9-container'>
            <Container>
                <TitleContainer>
                    <Title className='section-9--title'>Steps to increase</Title>
                    <SubTitle className='section-9--title'>Agroecological <br /> practices</SubTitle>
                    <Sub2Title className='section-9--title'>in Scotland</Sub2Title>
                </TitleContainer>
            </Container>
            {isMobile ? (
                <>
                    <ScrollPointsContainer>
                        <ScrollMore white />
                    </ScrollPointsContainer>
                    <SnapWrapper ref={scrollContainerRef}>
                        <InformationGrid>
                            <InformationPointOutter>
                                <InformationPoint className='section-9-point'>
                                    <InformationPointTitle>Research</InformationPointTitle>
                                    <img src="research.svg" alt="" />
                                    <InfoPointText>
                                        Evidence should be sought to show environmental, economic and social outcomes
                                    </InfoPointText>
                                </InformationPoint>
                            </InformationPointOutter>
                            <InformationPointOutter>
                                <InformationPoint className='section-9-point'>
                                    <InformationPointTitle>Policy</InformationPointTitle>
                                    <img src="policy.svg" alt="" />
                                    <InfoPointText>
                                        Social and financial support is needed to increase uptake
                                    </InfoPointText>
                                </InformationPoint>
                            </InformationPointOutter>
                            <InformationPointOutter>
                                <InformationPoint className='section-9-point'>
                                    <InformationPointTitle>Farming <br />Practice</InformationPointTitle>
                                    <img src="practice.svg" alt="" />
                                    <InfoPointText>
                                        Training should be supported and knowledge shared

                                    </InfoPointText>
                                </InformationPoint>
                            </InformationPointOutter>
                            <InformationPointOutter>
                                <InformationPoint className='section-9-point'>
                                    <InformationPointTitle>General <br /> Public</InformationPointTitle>
                                    <img src="general-public.svg" alt="" />
                                    <InfoPointText>
                                        Awareness should be raised of benefits to food quality, climate change and biodiversity
                                    </InfoPointText>
                                </InformationPoint>
                            </InformationPointOutter>
                        </InformationGrid>
                    </SnapWrapper>
                    <BackgroundWrapper className="section-9-background">
                        <img src='/Section9Map.svg' alt='Map of the UK' />
                    </BackgroundWrapper>
                </>
            ) : (
                <>
                    <Container>
                        <InformationGrid>
                            <Dialog>
                                <DialogTrigger asChild>
                                    <InformationPoint className='section-9-point'>
                                        <InformationPointTitle>Research</InformationPointTitle>
                                        <img src="research.svg" alt="" />
                                        <InfoPointText>
                                            Evidence should be sought to show environmental, economic and social outcomes
                                        </InfoPointText>
                                    </InformationPoint>
                                </DialogTrigger>
                                <DialogContent >
                                    <DialogParagraph>
                                        Evidence should be sought to show environmental, economic and social outcomes
                                    </DialogParagraph>
                                    <DialogClose asChild>
                                        <IconButton>
                                            <Cross2Icon />
                                        </IconButton>
                                    </DialogClose>
                                </DialogContent>
                            </Dialog>


                            <Dialog>
                                <DialogTrigger asChild>
                                    <InformationPoint className='section-9-point'>
                                        <InformationPointTitle>Policy</InformationPointTitle>
                                        <img src="policy.svg" alt="" />
                                        <InfoPointText>
                                            Social and financial support is needed to increase uptake
                                        </InfoPointText>
                                    </InformationPoint>
                                </DialogTrigger>
                                <DialogContent >
                                    <DialogParagraph>
                                        Social and financial support is needed to increase uptake
                                    </DialogParagraph>
                                    <DialogClose asChild>
                                        <IconButton>
                                            <Cross2Icon />
                                        </IconButton>
                                    </DialogClose>
                                </DialogContent>
                            </Dialog>

                            <Dialog>
                                <DialogTrigger asChild>
                                    <InformationPoint className='section-9-point'>
                                        <InformationPointTitle>Farming <br />Practice</InformationPointTitle>
                                        <img src="practice.svg" alt="" />
                                        <InfoPointText>
                                            Training should be supported and knowledge shared

                                        </InfoPointText>
                                    </InformationPoint>
                                </DialogTrigger>
                                <DialogContent >
                                    <DialogParagraph>
                                        Training should be supported and knowledge shared
                                    </DialogParagraph>
                                    <DialogClose asChild>
                                        <IconButton>
                                            <Cross2Icon />
                                        </IconButton>
                                    </DialogClose>
                                </DialogContent>
                            </Dialog>

                            <Dialog>
                                <DialogTrigger asChild>

                                    <InformationPoint className='section-9-point'>
                                        <InformationPointTitle>General <br /> Public</InformationPointTitle>
                                        <img src="general-public.svg" alt="" />
                                        <InfoPointText>
                                            Awareness should be raised of benefits to food quality, climate change and biodiversity
                                        </InfoPointText>
                                    </InformationPoint>
                                </DialogTrigger>
                                <DialogContent >
                                    <DialogParagraph>
                                        Awareness should be raised of benefits to food quality, climate change and biodiversity
                                    </DialogParagraph>
                                    <DialogClose asChild>
                                        <IconButton>
                                            <Cross2Icon />
                                        </IconButton>
                                    </DialogClose>
                                </DialogContent>
                            </Dialog>


                        </InformationGrid>
                    </Container>
                    <BackgroundWrapper className="section-9-background">
                        <img src='/Section9Map.svg' alt='Map of the UK' />
                    </BackgroundWrapper>
                </>
            )}
        </SectionWrapper>
    )
}

export default Section9