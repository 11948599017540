import { motion } from 'framer-motion'
import React from 'react'

const introductionAnimation = {
    initial: {
        opacity: 0,
        y: -100,
    },
    animate: {
        opacity: 1,
        y: 0,
        transition: {
            duration: 1,
            ease: [0.6, 0.05, -0.01, 0.9],
        },
    },
    exit: {
        opacity: 0,
        y: 100,
        transition: {
            duration: .5,
            ease: [0.6, 0.05, -0.01, 0.9],
        },
    }
}

const introductionContainer = {
    initial: {
        opacity: 1,
    },
    animate: {
        opacity: 1,
    },
    exit: {
        height: 0,
        transition: {
            delay: .5,
            duration: 1,
            ease: [0.6, 0.05, -0.01, 0.9],
        },
    }
}

const Introduction = () => {
    return (
        <motion.div
            variants={introductionContainer}
            animate={introductionContainer.animate}
            initial={introductionContainer.initial}
            exit={introductionContainer.exit}
            className='introduction--wrapper'>
            <motion.h1
                animate={introductionAnimation.animate}
                initial={introductionAnimation.initial}
                exit={introductionAnimation.exit}
                variants={introductionAnimation}
                className='introduction--header'>Loading...</motion.h1>
        </motion.div>
    )
}

export default Introduction