import { styled } from "../../../theme.stitches";


const Flex = styled('div', {
    display: 'flex',

    variants: {
        direction: {
            row: {
                flexDirection: 'row',
            },
            column: {
                flexDirection: 'column',
            },
        },
        spaceBetween: {
            true: {
                justifyContent: 'space-between',
            },
        },
        justifyCenter: {
            true: {
                justifyContent: 'center',
            },
        },
        alignItemsCenter: {
            true: {
                alignItems: 'center',
            },
        },
    },

    defaultVariants: {
        direction: 'row',
        spaceBetween: false,
        alignItemsCenter: false,
    }
});

export default Flex;