import { AnimationProps, motion, MotionConfig, useAnimation, Variants } from 'framer-motion'
import React, { useEffect } from 'react'
import { useInView } from 'react-intersection-observer'

const defaultAnimation: Variants = {
    initial: {
        opacity: 0,
        y: -20
    },
    animate: {
        opacity: 1,
        y: 0,
        transition: {
            duration: 1.6,
            ease: [0.6, 0.05, -0.01, 0.9],
            staggerChildren: 0.2
        }
    }
}



const Animate = ({
    animate = defaultAnimation,
    children
}: {
    animate?: Variants,
    children: React.ReactNode
}) => {

    const controls = useAnimation()
    const [ref, inView] = useInView({
        threshold: 0.4,
    });

    useEffect(() => controls.set('initial'), [controls])

    useEffect(() => {
        if (inView) controls.start("animate");
    }, [controls, inView])

    return (
        <motion.div
            animate={controls}
            ref={ref}
            variants={animate}
        >
            {children}
        </motion.div>
    )
}

Animate.variants = defaultAnimation;

export default Animate
