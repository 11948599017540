import { useEffect, useState } from "react";

//MAYBE NOT THE BEST WAY TO DO IT???
//Maybe a regex call - but that would be a bit more complex
//with the styling?

//isMobile regex call
// const useIsMobile = () => {
//     const [isMobile, setIsMobile] = useState(false);


const useIsMobile = () => {
    const [isMobile, setIsMobile] = useState(true);

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth < 920);
        };
    
        window.addEventListener("resize", handleResize);
        handleResize();
    
        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);
    
    return isMobile;
};
    

export default useIsMobile