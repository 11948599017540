import { motion } from 'framer-motion';
import React, { useEffect, useRef } from 'react'
import generateSection6Animations from '../animation/Section6';
import Container from '../components/core/container/Container';
import ScrollMore from '../components/ScrollMore';
import useIsMobile from '../hooks/useMobile';
import { styled } from '../theme.stitches'

const SectionWrapper = styled('div', {
    backgroundColor: '#222222',
    paddingBottom: '10rem',
    minHeight: '100vh',

    '@mobile': {
        paddingBottom: '3rem',
        backgroundColor: 'white'
    }
})


const TitleContainer = styled('div', {
    marginBottom: '4rem',
    paddingTop: '5rem',

    '@mobile': {
        marginBottom: '4rem',
        paddingTop: '2rem'
    }
});

const Title = styled('h3', {
    fontFamily: '$primary',
    fontSize: '2rem',
    lineHeight: '3rem',
    fontWeight: '600',
    color: '$white',
    textAlign: 'center',
    marginBottom: '1rem',

    '@mobile': {
        color: '#8C54A2',
        fontSize: '1.7rem',
        lineHeight: '1.8rem'
    }
});

const SubTitle = styled('h3', {
    fontFamily: '$primary',
    fontSize: '1.4rem',
    lineHeight: '2rem',
    fontWeight: '400',
    color: '$black',
    textAlign: 'center',

    '@mobile': {
        color: 'black',
        fontSize: '1.3rem',
        lineHeight: '1.8rem'
    }
});

const ChartWrapper = styled('div', {

    width: '100%',
    'img': {
        width: '100%'
    },
    '@mobile': {
        width: '100%',
        paddingBottom: '4rem',
        boxSizing: 'border-box',
        height: '60vh',
        overflowX: 'hidden',
        'img': {
            width: 'auto',
            height: '100%'
        }
    }

});



const Section6 = () => {

    const isMobile = useIsMobile();
    const scrollContainer = useRef(null);

    // useEffect(() => generateSection6Animations(isMobile), []);

    if (!isMobile) return null;

    return (
        <SectionWrapper className='section-6-container'>
            <Container>
                <TitleContainer>
                    <Title className="section-6--title">Positive change through agroecological practices</Title>
                    <SubTitle className="section-6--title">Did agroecological practices help improve bioversity and resilience?</SubTitle>
                </TitleContainer>
            </Container>

            {isMobile ? (
                <>
                    <Container>
                        <ScrollMore />
                    </Container>
                    <ChartWrapper ref={scrollContainer}>
                        <motion.img
                            drag="x"
                            dragElastic={0.05}
                            dragConstraints={scrollContainer}
                            dragTransition={{
                                bounceStiffness: 100,
                                bounceDamping: 100,
                                power: .2
                            }}
                            src='Section6Chart.svg'
                            alt="Section 6 Chart" />
                    </ChartWrapper>

                </>
            ) : (
                <Container>
                    <ChartWrapper className="section-6-chart">
                        <img src='Section6Chart.svg' alt="Section 6 Chart" />
                    </ChartWrapper>
                </Container >
            )}

        </SectionWrapper >
    )
}

export default Section6 