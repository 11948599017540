import gsap from 'gsap';

const animateTimelineSection3 = (isMobile: boolean) => {
    const timeline = gsap.timeline({
        scrollTrigger: {
            trigger: '.section-3-container',
            start: 'top 70%',
            id: 'section-3-timeline',
            toggleActions: 'play none none reverse',
        }
    })
    
    timeline.fromTo('.section-3--title', {
        opacity: 0,
        y: -20,
        duration: 1,
    }, {
        opacity: 1,
        y: 0,
        stagger: .2,
        ease: 'power1.out',
        duration: 1,
    })

    timeline.fromTo('.section-card-3', {
        y: -100,
        opacity: 0,
        duration: .5,
    }, {
        y: 0,
        opacity: 1,
        stagger: .2,
        reversed: false,
        ease: 'power1.out',
        duration: .5,
    }, "-=1")


    
    // if (!isMobile) {

    //     const timeline2 = gsap.timeline({
    //         scrollTrigger: {
    //             trigger: '.section-3-container',
    //             start: 'top top',
    //             end: "+=" + (window.innerHeight * 3),
    //         }
    //     })



    //     timeline2.fromTo('.section-card-3', {
    //         x: '15vw',
    //         duration: 1,
    //     }, {
    //         x: '-15vw',
    //         stagger: .15,
    //         reversed: false,
    //         duration: 2,
    //     }, "-=.1")a

            
 
    //Animate in stagger section-card-3 class

        
    // .to('.section-3--title', {
    //     opacity: 0,
    //     y: -20,
    //     duration: 1,
    // })
        


        

    


}

export default animateTimelineSection3;