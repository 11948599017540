import React from 'react'
import Button from '../components/core/button/Button';
import Container from '../components/core/container/Container';
import { styled } from '../theme.stitches';

import { ReactComponent as SVGArrow } from '../assets/arrow-up.svg';
import Anchor from '../components/core/anchor/Anchor';
import useIsMobile from '../hooks/useMobile';

const ReadMoreContainer = styled('div', {
    backgroundColor: '#29A4CD',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '3rem'
});

const FooterContainer = styled('div', {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',

    '@mobile': {
        padding: '0 2rem',
        alignItems: 'normal'
    }

});

const FooterHeader = styled('span', {
    fontFamily: '$primary',
    fontSize: '1.5rem',
    lineHeight: '2rem',
    fontWeight: '400',
    color: '#29A4CD',
    margin: '5rem 0 5rem 0',


    '@mobile': {
        margin: '3rem 0 2rem 0',
        color: 'black'
    }
});

const CommitteeContainer = styled('div', {
    display: 'flex',
    gap: '6rem',
    flexDirection: 'row',
    justifyContent: 'center',

    '@mobile': {
        gap: '2rem',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        alignItems: 'flex-start'

    }
});

const CommiteeWrapper = styled('div', {

});

const CommiteeTitle = styled('span', {
    fontFamily: '$primary',
    fontSize: '.9rem',
    lineHeight: '2rem',
    fontWeight: '400',

    '@mobile': {
        fontSize: '1.2rem',
        lineHeight: '2rem',
    }

});

const CommiteeUl = styled('ul', {
    paddingLeft: '.9rem',
});

const CommiteeLi = styled('li', {
    fontFamily: '$primary',
    fontSize: '1rem',
    lineHeight: '2rem',
    fontWeight: '400',

    '@mobile': {
        lineHeight: '2.4rem',
        fontSize: '1.2rem',
    }
});

const SponsorsContainer = styled('div', {
    display: 'flex',
    gap: '4rem',
    flexDirection: 'row',
    justifyContent: 'center',
    alignContent: 'center',

    '@mobile': {
        display: 'grid',
        gridTemplateColumns: '1fr 1fr',
        justifyContent: 'center',
        gap: '2rem',
    },

    'img': {
        height: 50 / 16 + 'rem',
        '@mobile': {
            width: '100%',
            height: 'auto',
        },
    }
});

const SponserTitle = styled('span', {
    fontFamily: '$primary',
    fontSize: '.9rem',
    lineHeight: '1.5rem',
    margin: '2rem 0 4rem 0'
})

const SponserFooterTitle = styled('span', {
    fontFamily: '$primary',
    fontSize: '1.15rem',
    lineHeight: '1.7rem',
    margin: '4rem 0 2rem 0',

    '@mobile': {
        fontSize: '1.2rem',
    }
})

const PingFooterTitle = styled('span', {
    fontFamily: '$primary',
    color: '$grey4',
    fontSize: '.8rem',
    lineHeight: '1.7rem',
    margin: '4rem 0 2rem 0',

    '@mobile': {
        textAlign: 'center',
    }
})

const PingAnchor = styled('a', {
    fontFamily: '$primary',
    color: '$grey6',
    fontSize: '.8rem',
    lineHeight: '1.7rem',
})


const BackgroundWrapper = styled('div', {
    width: '100%',
    'img': {
        width: '100%'
    }
})

const ScrollUpContainer = styled('div', {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    background: '#6C7E35',
    padding: '4rem 0',
    marginTop: '-1rem',
});

const ScrollUpButton = styled('button', {
    fontFamily: '$primary',
    fontSize: '1rem',
    color: '$white',
    background: 'none',
    border: 'none',
    fontWeight: '400',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    gap: '2rem',
    cursor: 'pointer',
});

const Section10 = ({ appRef }: any) => {

    const scrollToTop = () => {
        window.scrollTo(0, 0);
    }

    const isMobile = useIsMobile()

    return (
        <>
            <ReadMoreContainer>
                <Anchor href="https://sefari.scot/document/the-adoption-of-agroecological-principles-in-scottish-farming-and-their-contribution" style="white">Read the full report here</Anchor>
            </ReadMoreContainer>

            <FooterContainer>
                <FooterHeader>SEFARI Gateway Fellowship</FooterHeader>
                <CommitteeContainer>
                    <CommiteeWrapper>
                        <CommiteeTitle>Fellows:</CommiteeTitle>
                        <CommiteeUl>
                            <CommiteeLi>Luz-Maria Lozada (James Hutton Institute)</CommiteeLi>
                            <CommiteeLi>Alison Karley (James Hutton Institute)</CommiteeLi>
                        </CommiteeUl>
                    </CommiteeWrapper>
                    <CommiteeWrapper>
                        <CommiteeTitle>Fellowship committee:</CommiteeTitle>
                        <CommiteeUl>
                            <CommiteeLi>Lorna Dawson (SEFARI)</CommiteeLi>
                            <CommiteeLi>Sue Pritchard (FFCC)</CommiteeLi>
                            <CommiteeLi>Tom Burston(FFCC)</CommiteeLi>
                            <CommiteeLi>Jim Booth (SAOS)</CommiteeLi>
                            <CommiteeLi>Aoife Behan (SAS)</CommiteeLi>
                        </CommiteeUl>
                    </CommiteeWrapper>
                </CommitteeContainer>

                <SponserTitle>SEFARI fellowship in partnership with:</SponserTitle>

                <Container>
                    <SponsorsContainer>
                        <img src='logo1.png' alt='logo' />
                        <img src='logo2.png' alt='logo' />
                        <img src='logo3.png' alt='logo' />
                        <img src='logo4.png' alt='logo' />
                        <img src='logo5.png' alt='logo' />
                        <img src='logo6.png' alt='logo' />
                    </SponsorsContainer>
                </Container>

                <SponserFooterTitle>Funded by SEFARI Gateway and FFCC</SponserFooterTitle>
                <PingFooterTitle>Website by <PingAnchor href="https://pingcreates.com">Ping Creates</PingAnchor></PingFooterTitle>

                {!isMobile && (
                    <>

                        <BackgroundWrapper>
                            <img src='section1Grass.svg' alt='grass' />
                        </BackgroundWrapper>


                        <ScrollUpContainer>
                            <ScrollUpButton onClick={() => scrollToTop()}>
                                Back to top <SVGArrow />
                            </ScrollUpButton>
                        </ScrollUpContainer>
                    </>
                )}
            </FooterContainer>

            {isMobile && (
                <>

                    <BackgroundWrapper>
                        <img src='section1Grass.svg' alt='grass' />
                    </BackgroundWrapper>


                    <ScrollUpContainer>
                        <ScrollUpButton onClick={() => scrollToTop()}>
                            Back to top <SVGArrow />
                        </ScrollUpButton>
                    </ScrollUpContainer>
                </>
            )}
        </>
    )
}

export default Section10