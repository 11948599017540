import { motion } from 'framer-motion';
import React, { useEffect, useRef } from 'react'
import generateSection7Animations from '../animation/Section7';
import Container from '../components/core/container/Container';
import ScrollMore from '../components/ScrollMore';
import useIsMobile from '../hooks/useMobile';
import { styled } from '../theme.stitches'

const SectionWrapper = styled('div', {
    position: 'relative',
    width: '100vw',
    minHeight: '100vh',
    background: 'linear-gradient(180deg, rgba(60, 173, 255, 0) 0%, #3CADFF 100%)',

    '@mobile': {
        minHeight: '40rem',
    }
});

const TitleContainer = styled('div', {
    marginBottom: '4rem',
    marginTop: '4rem',
});

const Title = styled('h3', {
    fontFamily: '$primary',
    fontSize: '2.5rem',
    lineHeight: '3rem',
    fontWeight: '400',
    textAlign: 'center',
    margin: '0 0 1rem 0',

    '@mobile': {
        fontSize: '1.75rem',
        lineHeight: '2rem'
    }
});

const SubTitle = styled('h3', {
    fontFamily: '$primary',
    fontSize: '5rem',
    lineHeight: '6.2rem',
    fontWeight: '900',
    textAlign: 'center',
    margin: 0,
    textTransform: 'uppercase',

    '@mobile': {
        fontSize: '1.9rem',
        lineHeight: '2rem',
        marginBottom: '2rem'
    }
});
const SectionCardOuter = styled('div', {
    width: '100vw',
    scrollSnapAlign: "start",

});


const SectionCard = styled('div', {
    boxSizing: 'border-box',
    border: '1px solid #ECF2DB',
    boxShadow: '0px 0px 1px #D1D1D1',
    borderRadius: '2rem',
    width: '22.5rem',
    padding: '0 2rem',
    height: '11.75rem',
    margin: '0 1.5rem',
    transition: 'all 0.3s ease-in-out',
    background: 'rgba(255, 255, 255, 0.95)',
    backdropFilter: 'blur(20px)',

    '&:before': {
        content: "",
        width: 0,
        height: 0,
        position: "absolute",
        borderLeft: "10px solid white",
        borderRight: "10px solid transparent",
        borderTop: "10px solid white",
        borderBottom: "10px solid transparent",
        left: "19px",
        bottom: "-19px",
    },

    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',

    '@mobile': {
        margin: '0 auto'
    }
});

const CardTitle = styled('span', {
    fontFamily: '$primary',
    fontSize: '1.5rem',
    fontWeight: '700',
    textAlign: 'center',
    color: '#A6BF49',
    textTransform: 'uppercase',
});

const CardBody = styled('p', {
    fontFamily: '$primary',
    fontSize: '1.25rem',
    lineHeight: '1.65rem',
    fontStyle: 'italic',
    fontWeight: '400',
    textAlign: 'center',
    color: '#222222',
});

const CardsWrapper = styled(motion.div, {
    display: 'inline-flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    flexWrap: 'nowrap',

    '@mobile': {
        alignItems: 'flex-start',
    }
});

const CardOutterWrapper = styled(motion.div, {
    width: '100vw',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    height: '15rem',

    '@mobile': {
        padding: '2rem 0',
        overflowX: 'scroll',
        scrollSnapType: 'x mandatory',

        '-ms-overflow-style': 'none',
        '&::-webkit-scrollbar': {
            display: 'none',
        },
    }
});

const CardOutterWrapperMobile = styled(motion.div, {
    marginTop: '2.5rem',
    padding: '1rem 0',
    overflowX: 'scroll',
    scrollSnapType: 'x mandatory',
    scrollbarWidth: 'none',

    '-ms-overflow-style': 'none',
    '&::-webkit-scrollbar': {
        display: 'none',
    },

    '@mobile': {
        height: '13rem'
    }
});


const BackgroundWrapper = styled('div', {
    position: 'absolute',
    zIndex: '1000',
    width: '100%',
    bottom: '-.25rem',
    'img': {
        marginBottom: '-5px',
        width: '100%',
    }
});

const ContentContainer = styled('div', {
    padding: '6rem 0 0 0',
    zIndex: '100',
    position: 'relative',

});

const InformationGrid = styled(motion.div, {
    '@mobile': {
        marginLeft: '3.5rem',
        marginRight: '3.5rem',
        gridTemplateColumns: '1fr 1fr 1fr 1fr',
        gap: '4rem',
    }
});


const Section7 = () => {

    const scrollContainer = useRef(null);
    const isMobile = useIsMobile();

    useEffect(() => generateSection7Animations(isMobile), [])

    return (
        <SectionWrapper className='section-7-container'>
            <BackgroundWrapper>
                <img src='/section1Grass.svg' />
            </BackgroundWrapper>
            <Container>
                <TitleContainer>
                    <Title className="section-7--title">Farmers' thoughts on</Title>
                    <SubTitle className="section-7--title">AGROECOLOGY</SubTitle>
                    {isMobile && (<ScrollMore />)}
                </TitleContainer>
            </Container>

            {isMobile ? (
                <CardOutterWrapperMobile ref={scrollContainer}>
                    <CardsWrapper>
                        <SectionCardOuter>

                            <SectionCard className="section-7-card">
                                <CardBody>“I think there are real challenges about holistic decision making and holistic planning which is really adaptive.”</CardBody>
                            </SectionCard>
                        </SectionCardOuter>
                        <SectionCardOuter>

                            <SectionCard className="section-7-card">
                                <CardBody>“There is a deeper connection if your farmer actually feeds you.”</CardBody>
                            </SectionCard>
                        </SectionCardOuter>
                        <SectionCardOuter>

                            <SectionCard className="section-7-card">
                                <CardBody>“I want to run an economically viable agricultural business but I also want this land to be in the best condition possible.”</CardBody>
                            </SectionCard>
                        </SectionCardOuter>
                    </CardsWrapper>
                </CardOutterWrapperMobile>
            ) : (
                <CardOutterWrapper ref={scrollContainer}>
                    <CardsWrapper>
                        <SectionCard className="section-7-card">
                            <CardBody>“I think there are real challenges about holistic decision making and holistic planning which is really adaptive.”</CardBody>
                        </SectionCard>
                        <SectionCard className="section-7-card">
                            <CardBody>“There is a deeper connection if your farmer actually feeds you.”</CardBody>
                        </SectionCard>
                        <SectionCard className="section-7-card">
                            <CardBody>“I want to run an economically viable agricultural business but I also want this land to be in the best condition possible.”</CardBody>
                        </SectionCard>
                    </CardsWrapper>
                </CardOutterWrapper>
            )}

        </SectionWrapper>
    )
}

export default Section7