import React from 'react'
import { keyframes, styled } from '../theme.stitches'
import { ReactComponent as RightArrow } from '../assets/arrow-right-circle.svg';
import { transform } from 'typescript';

const bounceAnimation = keyframes({
    '0%': {
        transform: 'rotate(180deg) translateX(0)'
    },
    '50%': {
        transform: 'rotate(180deg) translateX(10px)'
    },
    '100%': {
        transform: 'rotate(180deg) translateX(0)'
    },
})

const ScrollContainer = styled('div', {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',

    color: 'black',
    fontSize: '1.25rem',
    lineHeight: '1.5rem',
    fontFamily: '$primary',
    marginBottom: '2rem',

    'svg': {
        marginRight: '1rem',
        width: '2rem',
        height: '2rem',

        animation: `${bounceAnimation} 4s cubic-bezier(0.16, 1, 0.3, 1)`,
        animationIterationCount: 'infinite'

    },

    variants: {
        white: {
            true: {
                color: '$white',
                'svg line': {
                    stroke: 'white'
                },
                'svg polyline': {
                    stroke: 'white'
                },
                'svg circle': {
                    stroke: 'white'
                }
            }
        }
    }
})

const ScrollMore = ({ white = false }: any) => {
    return (
        <ScrollContainer white={white}>
            <RightArrow /> Swipe left to view more
        </ScrollContainer>
    )
}

export default ScrollMore